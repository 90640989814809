import React, { useEffect, useState } from "react";
import './Navbar.css';
import Logo from '../Images/Logo.svg'
import Arrow from '../Images/arrowIcon.svg'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios'
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);
const Navbar = () =>{
  const navigate = useNavigate()
    const [isSubscribe, setIsSubscribe] = useState(false);
    const showAlert = () => {
        MySwal.fire({
          html: `
          <div class="swal2-image">
            <img src="${Logo}" alt="Logo" style="width: 100px; height: auto;">
            </div>
            <p class="swal2-paragraph">Drop your email & you will be the first to know when itsBangali is available.</p>
            <input type="email" id="swal-input1" class="swal2-input" placeholder="Email ID">`,
          confirmButtonText: 'Continue',
          buttonsStyling: false,
          customClass: {
            popup: 'custom-swal-popup',
            confirmButton: 'swal2-confirm'
          },
          focusConfirm: false,
          preConfirm: () => {
            const email = document.getElementById('swal-input1').value;
            if (!email) {
              Swal.showValidationMessage("Please enter an email address");
              return false;
            }
            return { email: email };
          }
        }).then(async(result) => {
          if (result.isConfirmed) {
            try{
             let response = await axios.post('https://itsbangali.com/api/sendmail',{email:result.value.email});
             setIsSubscribe(response.data.message);
            }catch(error){
              console.error(error)
            }
          }
        });
      };
      
      useEffect(()=>{
        if(isSubscribe){
          Swal.fire({
            html:`<p>${isSubscribe}</p>`,
            customClass: {
              popup: 'custom-swal-popup1',
              confirmButton: 'custom-confirm-button'
            },
            confirmButtonText: 'Got it',
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `
            },
            hideClass: {
              popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `
            }
          });
        }
      
      },[isSubscribe])

    return(
        <>
        <div className="navbar-container">
            <div className="navbar-wrapper">
                 <div className="navbar-logo" onClick={()=>navigate('/')}>
                <img src={Logo} alt="Logo" height='100%' width='100%'/>
            </div>
            <div className="navbar-btn">
                <div><a href="https://drive.google.com/file/d/1YmySRRLzm6CDP4gwgsE5mOg-tYTHs43I/view?usp=sharing" target="_blank">Get Our Pitch Deck</a><span style={{ marginLeft: "5px",color:"#333" }}>⇒</span></div>
            </div>
            </div>
           
        </div>
        </>
    )
}

export default Navbar