import React, { useEffect, useRef, useState } from "react";
import './Home.css';
import './PitchDeckFrom.css';
import Logo from '../Images/Logo.svg'
import Navbar from "./Navbar";
import S1 from '../Images/S1.svg';
import S2 from '../Images/S2.svg';
import S3 from '../Images/S3.svg';
import S4 from '../Images/S4.svg';
import Arrow from '../Images/arrowIcon.svg'
import MovieBanner from '../Images/Moviebanner.svg'
import BookBanner from '../Images/Book.svg'
import ComicsBanner from '../Images/Comics.svg'
import MagaZineBanner from '../Images/Magazine.svg'
import AudioBanner from '../Images/Audio Drama.svg'
import AstrologyBanner from '../Images/Astrology.svg'
import MovileIcon from '../Images/mobileIcon.svg'
import TabletIcon from '../Images/tabletIcon.svg'
import LaptopIcon from '../Images/laptopIcon.svg'
import SmartTvIcon from '../Images/smartTvIcon.svg'
import LinkedIn from '../Images/linkedinIcon.svg'
import LinearLine from '../Images/linearline.svg'
import one from '../Images/1.png'
import two from '../Images/2.png'
import three from '../Images/3.png'
import five from '../Images/4.png'
import four from '../Images/5.jpeg'
import six from '../Images/6.png'
import Lottie from '@lottielab/lottie-player/react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios'
import PitchDeckFrom from "./PitchDeckFrom";
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);
const Home = ()=>{
  const navigate = useNavigate()
  const navContainerRef = useRef(null);
  const [intervalTime, setIntervalTime] = useState(3000)
  const [isSubscribe, setIsSubscribe] = useState(false);
  const userType1 = window.location.search.split('fromId=')[1]?.split('&')[0];
  const [newPopup,setNewPopup] = useState(true);
  const [showStickyFooter, setShowStickyFooter] = useState(false);
  const originalButtonsRef = useRef(null);
  const refs = {
    'Movies': useRef(null),
    'Books': useRef(null),
    'Comics': useRef(null),
    'Magazine': useRef(null),
    'Audio Drama': useRef(null),
    'Astrology': useRef(null)
  };
    const [visibleOffering,setVisibleOfferings] = useState('Movies')
    const [animationKey, setAnimationKey] = useState(0);
 const allOfferings = ['Movies','Books','Comics','Magazine','Audio Drama','Astrology']
const [visibleOfferingContent,setVisibleOfferingContent] = useState({
  imgUrl:MovieBanner,
  content:'From cult classics to modern marvels, ItsBangali brings to you a diverse collection of films & web series. Find something for your every mood.'
})
 const [faqs, setFaqs] = useState([
  { question: 'What is It’sBangali?', answer: [`It’s Bangali is an innovative venture aimed at revolutionising Bengali entertainment worldwide. Our mission is to create the ultimate Bengali app catering to the diverse needs of Bengalis from India, Bangladesh, and the global diaspora. By offering a comprehensive range of authentic Bangla content including ebooks, films, theatre productions, audio dramas and documentaries, we aim to bridge cultural gaps and nurture a deeper connection to Bengali heritage.`]},
  { question: 'What content is available on It’sBangali?', answer: [`It’sBangali offers a diverse range of Bengali films, ebooks, theatre productions, audio dramas, documentaries & magazines among others. Made for people from all walks of life & all age groups, It’sBangali is your go-to app for any & all things Bengali.`]},
  {question:'How can I access the content on It’sBangali?',answer:[`Access all It’sBangali content at the click of a button! All you need to do is download the app on your device, or alternatively access our website, create your account and - viola! You now have access to all It’sBangali content. You can either opt for a monthly subscription to be able to access any & all content. You can also opt for our pay per use model by individually purchasing the piece of content that you want to consume. Enjoy!`]},
]);

const showAlert = () => {
  MySwal.fire({
    html: `
    <div class="swal2-image">
      <img src="${Logo}" alt="Logo" style="width: 100px; height: auto;">
      </div>
      <p class="swal2-paragraph">Drop your email & you will be the first to know when itsBangali is available.</p>
      <input type="email" id="swal-input1" class="swal2-input" placeholder="Email ID">`,
    confirmButtonText: 'Continue',
    buttonsStyling: false,
    customClass: {
      popup: 'custom-swal-popup',
      confirmButton: 'swal2-confirm'
    },
    focusConfirm: false,
    preConfirm: () => {
      const email = document.getElementById('swal-input1').value;
      if (!email) {
        Swal.showValidationMessage("Please enter an email address");
        return false;
      }
      return { email: email };
    }
  }).then(async(result) => {
    if (result.isConfirmed) {
      try{
       let response = await axios.post('https://itsbangali.com/api/sendmail',{email:result.value.email});
       setIsSubscribe(response.data.message);
      }catch(error){
        console.error(error)
      }
    }
  });
};

useEffect(()=>{
  if(userType1){
    setNewPopup(false)
  }
},[userType1])

useEffect(()=>{
  if(isSubscribe){
    Swal.fire({
      html:`<p>${isSubscribe}</p>`,
      customClass: {
        popup: 'custom-swal-popup1',
        confirmButton: 'custom-confirm-button'
      },
      confirmButtonText: 'Got it',
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `
      }
    });
  }

},[isSubscribe])


 useEffect(() => {
  if(intervalTime === 10000){
    const timer = setTimeout(() => {
     setIntervalTime(3000)
    }, 9000);

    return () => clearTimeout(timer);
  }
    const intervalId = setInterval(() => {
      setVisibleOfferings((currentOffering) => {
        const currentIdx = allOfferings.indexOf(currentOffering);
        const nextIdx = (currentIdx + 1) % allOfferings.length;
        return allOfferings[nextIdx];
      });
      // Increment animationKey to trigger re-rendering of the span with new key
      setAnimationKey(prevKey => prevKey + 1);
    }, intervalTime); // Change the offering every 5 seconds

    return () => clearInterval(intervalId);
  }, [intervalTime]);


  useEffect(() => {
    function handleScroll() {
      const scrollPosition = window.scrollY;
      const originalButtons = originalButtonsRef.current;
      const threshold = 200;

      if (!originalButtons) return;

      const { top } = originalButtons.getBoundingClientRect();

      if (scrollPosition > threshold && top <= 0) {
        setShowStickyFooter(true);
      } else {
        setShowStickyFooter(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const activeRef = refs[visibleOffering]?.current;
    const navContainer = navContainerRef.current;

    if (activeRef && navContainer) {
      let newScrollPosition = 0;
      if (visibleOffering === 'Astrology') {
        newScrollPosition = activeRef.offsetLeft + activeRef.offsetWidth - navContainer.offsetWidth;
      } else {
        newScrollPosition = activeRef.offsetLeft - (navContainer.offsetWidth / 2 - activeRef.offsetWidth / 2);
      }
      navContainer.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth'
      });
    }
  }, [visibleOffering]);

  useEffect(()=>{
    if(visibleOffering === 'Movies'){
      setVisibleOfferingContent({
        imgUrl:MovieBanner,
        content:'From cult classics to modern marvels, ItsBangali brings to you a diverse collection of films & web series. Find something for your every mood.'
      })
    }else if(visibleOffering === 'Books'){
      setVisibleOfferingContent({
        imgUrl:BookBanner,
        content:'Explore a diverse collection of Bengali books, covering a large range of genres, personalised according to your preferences. '
      })
    }else if(visibleOffering === 'Comics'){
      setVisibleOfferingContent({
        imgUrl:ComicsBanner,
        content:'Explore a diverse collection of Bengali comics, covering a large range of genres, personalised according to your preferences.'
      })
    }else if(visibleOffering === 'Magazine'){
      setVisibleOfferingContent({
        imgUrl:MagaZineBanner,
        content:'Explore a diverse collection of Bengali magazines, covering a large range of genres, personalised according to your preferences.'
      })
    }else if(visibleOffering === 'Audio Drama'){
      setVisibleOfferingContent({
        imgUrl:AudioBanner,
        content:'Explore a diverse collection of Bengali audio dramas, covering a large range of genres, personalised according to your preferences.'
      })
    }else if(visibleOffering === 'Astrology'){
      setVisibleOfferingContent({
        imgUrl:AstrologyBanner,
        content:'Have a question about your life? Worried about how you might fare with your life & career? We have curated a diverse range of astrologers to guide you through.'
      })
    }
  },[visibleOffering])

  const toggleFAQ = (index) => {
    const updatedFaqs = [...faqs];
    updatedFaqs[index].isOpen = !updatedFaqs[index].isOpen;
    setFaqs(updatedFaqs);
  };


  const changeVisibleOffering = (item)=>{
    setIntervalTime(10000)
    setVisibleOfferings(item)
  }

  const openPitchDeck = () =>{
    navigate('/form')
  }
const closePitchDeck = () =>{
  setNewPopup(false)
}
return(
    <>
        <Navbar/>
        <div className="home-container">
            {/* <div className="postar">
                <img src={Postar} alt="Postar" height='100%' width='100%' />
            </div> */}
              {/* <div className="postar">
              <Lottie src="https://cdn.lottielab.com/l/3TE1aswQnFrw4j.json" autoplay/>
            </div> */}
               {/* <div className="postar">
      <img src={S1} alt="Scrolling Image" className="scrolling-image" />
    </div> */}
   {/* {newPopup &&  <div className="login-confirmation-popup">
            <div className="login-popup-content">
              <div className="login-popup-header">
              <div className="navbar-logo1">
                <img src={Logo} alt="Logo" height='100%' width='100%'/>
            </div>
                <div className="login-email-title">Would you like to receive our pitch deck for investing in ItsBangali?</div>

              </div>
              <div>
    </div>
              <div style={{display:"flex",marginTop:"10px",justifyContent:"space-between"}}>
                 <div className="login-confirmation-button1" type="submit" onClick={openPitchDeck}>Yes</div>
                <div className="login-confirmation-button2" type="submit" onClick={closePitchDeck}>No</div>
              </div>
               
            </div>
        </div>} */}
    <div className="postar">
      <div className="scrolling-wrapper">
         <img src={S1} alt="Scrolling Image" className="scrolling-image" height='150px'/>
      <img src={S2} alt="Scrolling Image" className="scrolling-image2"height='150px' />
      <img src={S3} alt="Scrolling Image" className="scrolling-image"height='150px' />
      <img src={S4} alt="Scrolling Image" className="scrolling-image2"height='150px' />
      </div>
    </div>
            <div className="postar-content">
                <div className="first"></div>
                <div className="second">
                    <h1 className="heading">Experience all things Bangali,<br /> with the click of a button</h1>
                    <p className="paragraph">Experience Bangaliana at its best with It’sBangali - your go-to app for all things Bangali. Access a diverse range of Bengali content ranging from classic films, web series, audio dramas, documentaries & books within the palm of your hand. With a little something for everyone, It’sBangali tries to stay as close to our Bengali roots, with the core of Bangaliana at its heart.</p>
                    <div className="join-btn" ref={originalButtonsRef}>
                        <div onClick={()=>navigate('/form')}>Become an Investor <span style={{ marginLeft: "5px" }}>⇒</span></div>
                    </div>
                </div>
                {showStickyFooter && <div className="join-btn2">
                        <div onClick={()=>navigate('/form')}>Become an Investor <span style={{ marginLeft: "5px" }}>⇒</span></div>
                </div>}
                <div className="join-btn1">
                        <div onClick={()=>navigate('/form')}>Become an Investor <span style={{ marginLeft: "5px" }}>⇒</span></div>
                </div>
            </div>
            <div className='first-section-container'>
      <h2 className={`first-section-header`}>

      We are offerings <span key={animationKey} className="changing">{visibleOffering}</span>
      </h2>
      <p style={{textAlign:'center',marginTop:'-10px',opacity:'0.6',padding:'0px 20px'}}>{visibleOffering==='Astrology'?`If you have concerns about your future & would like some insights on the same, we have curated a list of astrologers to guide you.`:`Bengali ${visibleOffering} at the click of a button, personalised according to your preferences.`}</p>
      <nav className='first-section-nav'>
        <ul>
          <li className={visibleOffering==='Movies'?'active':''} onClick={()=>changeVisibleOffering('Movies')}>Movies & Series</li>
          <li className={visibleOffering==='Books'?'active':''} onClick={()=>changeVisibleOffering('Books')}>Books</li>
          <li className={visibleOffering==='Comics'?'active':''} onClick={()=>changeVisibleOffering('Comics')}>Comics</li>
          <li className={visibleOffering==='Magazine'?'active':''} onClick={()=>changeVisibleOffering('Magazine')}>Magazine</li>
          <li className={visibleOffering==='Audio Drama'?'active':''} onClick={()=>changeVisibleOffering('Audio Drama')}>Audio Drama</li>
          <li className={visibleOffering==='Astrology'?'active':''} onClick={()=>changeVisibleOffering('Astrology')}>Astrology</li>
        </ul>
      </nav>
      <div className='first-section-nav-mobile' ref={navContainerRef}>
      <ul>
        <li ref={refs['Movies']} className={visibleOffering === 'Movies' ? 'active' : ''} onClick={() => changeVisibleOffering('Movies')}>Movie & Series</li>
        <li ref={refs['Books']} className={visibleOffering === 'Books' ? 'active' : ''} onClick={() => changeVisibleOffering('Books')}>Books</li>
        <li ref={refs['Comics']} className={visibleOffering === 'Comics' ? 'active' : ''} onClick={() => changeVisibleOffering('Comics')}>Comics</li>
        <li ref={refs['Magazine']} className={visibleOffering === 'Magazine' ? 'active' : ''} onClick={() => changeVisibleOffering('Magazine')}>Magazine</li>
        <li ref={refs['Audio Drama']} className={visibleOffering === 'Audio Drama' ? 'active' : ''} onClick={() => changeVisibleOffering('Audio Drama')}>Audio Drama</li>
        <li ref={refs['Astrology']} className={visibleOffering === 'Astrology' ? 'active' : ''} onClick={() => changeVisibleOffering('Astrology')}>Astrology</li>
      </ul>
    </div>
      <div className="first-section-card">
        <div className="image-content">
            <img src={visibleOfferingContent.imgUrl} alt="*" height='100%' width='100%'/>
        </div>
        <div className="card-content">
            <h2 className="card-content-header">{visibleOffering}</h2>
            <p className="card-content-paragraph">{visibleOfferingContent.content}</p>
        </div>
      </div>
           </div>
           <div className="second-section-container">
            <h2 className="second-section-header">
            You can access It’sBangali content - anytime, anywhere.
            </h2>
            <p className="second-section-paragraph">With It’sBangali, you can enjoy your favourite movies, series, books, comics, magazines & audio dramas as per your convenience, anytime, anywhere in the world. Our platform is compatible with a wide range of devices to ensure you are entertained wherever you are, whenever you feel like it. </p>
              <div className="smartphone-and-tablet">
                <div style={{paddingRight:'15px'}}>
                   <div className="smartphone">
                  <div style={{marginLeft:'15px',display:'flex',alignItems:'center'}}>
                    <img src={MovileIcon} alt="*" style={{paddingRight:'10px'}} height="60px" width="60px" />
                    <h2 className="smartphone-header">Smartphones</h2>
                  </div>
                  <p style={{color:'#FFF',opacity:'0.6',marginLeft:'15px',paddingRight:'20px'}}>It’sBangali has been optimised for Android & iOS smartphones. Our app will be available to download on the Google Play Store & Apple App Store, soon.</p>
                </div>
                </div>
               
                <div className="tablet">
                  <div style={{marginLeft:'15px',display:'flex',alignItems:'center'}}>
                    <img src={TabletIcon} alt="*" style={{paddingRight:'10px'}} height="60px" width="60px"/>
                    <h2 className="smartphone-header">Tablet</h2>
                  </div>
                  <p style={{color:'#FFF',opacity:'0.6',marginLeft:'15px',paddingRight:'20px'}}>It’sBangali is optimised for both Android & iOS tablets. Our app will be available to download on the Google Play Store & Apple App Store, soon.</p>
                </div>
              </div>
              <div className="smartphone-and-tablet">
                <div style={{paddingRight:'15px'}}>
                   <div className="laptop">
                  <div style={{marginLeft:'15px',display:'flex',alignItems:'center'}}>
                    <img src={LaptopIcon} alt="*" style={{paddingRight:'10px'}} height="60px" width="60px" />
                    <h2 className="smartphone-header">Laptop</h2>
                  </div>
                  <p style={{color:'#FFF',opacity:'0.6',marginLeft:'15px',paddingRight:'20px'}}>It’sBangali is optimised for both Android & iOS devices. Our app will be available to download on the Google Play Store & Apple App Store, soon.</p>
                </div>
                </div>
               
                <div className="smartTv">
                  <div style={{marginLeft:'15px',display:'flex',alignItems:'center'}}>
                    <img src={SmartTvIcon} alt="*" style={{paddingRight:'10px'}} height="60px" width="60px"/>
                    <h2 className="smartphone-header">Smart TV</h2>
                  </div>
                  <p style={{color:'#FFF',opacity:'0.6',marginLeft:'15px',paddingRight:'20px'}}>It’sBangali is optimised for both Android & iOS devices. Our app will be available to download on the Google Play Store & Apple App Store, soon.</p>
                </div>
              </div>
           </div>
           <div className="faq-container">
            <h2 className="faq-header">Frequently Asked Questions</h2>
            <p className="faq-paragraph">Got questions? We've got answers! Check out our FAQ section to find answers to the most common questions about StreamVibe.</p>
      {faqs.map((faq, index) => (
        <>
                 <div className="question" onClick={() => toggleFAQ(index)} key={index}>
            <div className="question-left-section">
                  <div className="question-index">{`0${index+1}`}</div>
            <div className="question-header">
              <div>{`${faq.question}`}</div>
              {faq.isOpen && faq.answer.map((item,index)=>(
             <p key={index}>
            {item}
          </p>
          ))
          }
            </div>
            </div>
        
            <span>{faq.isOpen?'-':'+'}</span>
          </div>
          {/* <img src={LinearLine} alt="*" width='100%'/> */}
          <div className="hr-tag"></div>
        </>
 
      ))}
           </div>
           <div className="founding-member-container">
            <h2 className="founding-member-header">
            People Behind the Project
            </h2>
          {/* <p className="founding-member-paragraph">
          Got questions? We've got answers! Check out our FAQ section to find answers to the most common questions about StreamVibe.
          </p> */}
          <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",justifyContent:"center",gap:"20px"}}>
              <div className="founding-member-card">
            <div className="founding-member-image-container">
              <img src={one} alt="*" height='280px' width='340px'/>
            </div>
            <div className="founding-member-content">
              <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                 <h2 className="founding-member-content-header">Kaushik Banerjee</h2>
              <a href="https://www.linkedin.com/in/kaushik-banerjee-7bb4b48b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank"> <img src={LinkedIn} alt="*" /></a>
              </div>
             
              <p className="founding-member-content-paragraph">Mechanical Engineer from Jadavpur University and PGDM from IIM Lucknow. 26 years of sales and marketing experience across FMCG, Telecom, Media, FMCD and Building Materials.
              Set up the Digital Business for one of India's largest Media Houses.</p>
            </div>
          </div>
          <div className="founding-member-card">
            <div className="founding-member-image-container">
              <img src={two} alt="*" height='280px' width='340px'/>
            </div>
            <div className="founding-member-content">
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <h2 className="founding-member-content-header">Anirban Banerjee</h2>
              <a href="https://www.linkedin.com/in/anirban-banerjee07?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank"> <img src={LinkedIn} alt="*" /></a>
            </div>
              
              {/* <strong  style={{fontSize:'18px'}}>Founder</strong> */}
              <p className="founding-member-content-paragraph">Alumnus of St. Xavier's College (Autonomous) Kolkata. Graduated with distinction in the top 5% of the class in Mass Communication and Media Studies.
              5+ Years of professional experience in Marketing, Ad Sales, Advertising, Brand Management, UI/UX Designing and Product Strategy.</p>
            </div>
          </div>
          <div className="founding-member-card">
            <div className="founding-member-image-container">
              <img src={three} alt="*" height='280px' width='340px'/>
            </div>
            <div className="founding-member-content">
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <h2 className="founding-member-content-header">Indranii Chakraborty</h2>
              <a href="https://www.linkedin.com/in/indranii-chakraborty-467727243?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank"> <img src={LinkedIn} alt="*" /></a>
            </div>
              
              {/* <strong  style={{fontSize:'18px'}}>Founder</strong> */}
              <p className="founding-member-content-paragraph">Indranii brings over twenty years of expertise in overseeing Eastern region Operations for a
              German Conglomerate. She excels in guiding teams, facilitating seamless daily operations, and fostering interdepartmental collaboration to uphold customer satisfaction and drive long-term company growth, aligning with organizational goals and objectives.</p>
            </div>
          </div>
          <div className="founding-member-card">
            <div className="founding-member-image-container">
              <img src={four} alt="*" height='280px' width='340px'/>
            </div>
            <div className="founding-member-content">
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <h2 className="founding-member-content-header">Sandeep Bhattacharya</h2>
              <a href="https://www.linkedin.com/in/sandeep-bhattacharya-1567a48?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank"> <img src={LinkedIn} alt="*" /></a>
            </div>
              
              {/* <strong  style={{fontSize:'18px'}}>Founder</strong> */}
              <p className="founding-member-content-paragraph">Sandeep Bhattacharya, PhD in Pharmacology, has held leadership positions at top companies like Hoechst (HMR) and Merck, serving as VP of Marketing and Chief Marketing Officer. He was also Chief Innovation and Strategy Officer at Merck and International Product Manager at Hoechst AG. Currently, he is the Managing Director at Aureax Healthcare Consulting Pvt. Ltd.</p>
            </div>
          </div>
          <div className="founding-member-card">
            <div className="founding-member-image-container">
              <img src={five} alt="*" height='280px' width='340px'/>
            </div>
            <div className="founding-member-content">
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <h2 className="founding-member-content-header">Dr Saikat Ray</h2>
              <a href="https://www.linkedin.com/in/dr-saikat-ray-92b8381?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank"> <img src={LinkedIn} alt="*" /></a>
            </div>
              
              {/* <strong  style={{fontSize:'18px'}}>Founder</strong> */}
              <p className="founding-member-content-paragraph">With 34 years of experience, I excel in strategic insight, tactical execution, and driving business growth. My entrepreneurial approach boosts profits, stakeholder satisfaction, and manages complex changes. I've scaled operations, led acquisitions, achieved revenue growth, and revitalized businesses by recruiting top talent and making impactful decisions.</p>
            </div>
          </div>
          <div className="founding-member-card">
            <div className="founding-member-image-container">
              <img src={six} alt="*" height='280px' width='340px' style={{objectFit:"contain"}}/>
            </div>
            <div className="founding-member-content">
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <h2 className="founding-member-content-header">Dr Priyanka Ray</h2>
              <a href="https://www.linkedin.com/in/dr-priyanka-ray-834770266/" target="_blank"> <img src={LinkedIn} alt="*" /></a>
            </div>
              
              {/* <strong  style={{fontSize:'18px'}}>Founder</strong> */}
              <p className="founding-member-content-paragraph">Dr. Priyanka Ray has over 20 years of leadership experience at top IT companies like Wipro, Cognizant, Ericsson, and IBM, where she led large-scale transformation projects and product development. She has managed multi-billion-dollar service P&Ls, driving sales and delivery in Hybrid Cloud and Gen AI services. Dr. Ray has also spearheaded go-to-market strategies, driving business growth and innovation across various industries.</p>
            </div>
          </div>
          </div>
        
           </div>
           <div className="waitList-container">
            <div className="waitList-first-section">
              <h2 className="waitlist-header" onClick={()=>navigate('/form')}>Become an Investor</h2>
              <p className="waitList-paragraph">Want to be the first to know when It'sBangali is ready to download? Join the waitlist now!</p>
            </div>
            <div>
     <div className="join-btn">
                        <div onClick={()=>navigate('/form')}>Become an Investor <span style={{ marginLeft: "5px" }}>⇒</span></div>
                    </div>
           </div>

            </div>
       <div className="footer">
          <div className="footer-logo">
              <img src={Logo} alt="*" />
            <h1 className="footer-logo-header">itsBangali</h1>
          </div>
          <div className="footer-address">
            <p className="footer-paragraph">Contact: indranii@itsbangali.com</p>
            <p className="footer-paragraph">Address: DN Block, Sector V, Bidhannagar, Kolkata, West Bengal 700091.</p>
          </div>
          <div className="footer-copyright-section">
            <p className="footer-copyright-paragraph">Made in India with Love</p>
            <p className="footer-copyright-paragraph">Copyright © Aram Kedara Private Limited 2024</p>
          </div>
       </div>
        </div>
    </>
)

}
export default Home