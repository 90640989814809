import React from 'react';
import Home from './Components/Home.jsx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PitchDeckFrom from './Components/PitchDeckFrom.jsx';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/form" element={<PitchDeckFrom />} />
      </Routes>
     
    </Router>
  
  );
}

export default App;
