import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './PitchDeckFrom.css';
import axios from "axios";
import Navbar from './Navbar';

const PitchDeckFrom = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    mobileNo: '91',
    email: '',
    address: '',
    noOfShares: '',
    totalAmount: ''
  });
  const [investmentCount, setInvestmentCount] = useState(0)

  const handleChange = (e) => {
    
    if(e.target.name === 'noOfShares'){
      setFormData({ ...formData, ['totalAmount']: e.target.value*67200,[e.target.name]: e.target.value });
    }else{
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, mobileNo: value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    try{
      let response = await axios.post('https://itsbangali.com/api/sendmail',{formData});
      if(response.data.message === 'Success'){
        setFormData({
          fullName: '',
          mobileNo: '91',
          email: '',
          address: '',
          noOfShares: '',
          totalAmount: ''
        })
        window.alert('Success');
      }
     }catch(error){
      window.alert(error)
     }
  };
  const getInvestCount = async(e) => {
    try{
      let response = await axios.post('https://soulsway.in/api/getInvestor-count');
      if(response.data.count){
       setInvestmentCount(response.data.count)
      }
     }catch(error){
      console.error(error)
     }
  };
  useEffect(()=>{
    getInvestCount()
  },[])


  return (
    <>
    <Navbar/>
 <div className="form-container">
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <label htmlFor="fullName">Full Name</label>
          <input
            type="text"
            name="fullName"
            id="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="enter name"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="mobileNo">Mobile No</label>
          <PhoneInput
            country={'in'}
            value={formData.mobileNo}
            onChange={handlePhoneChange}
            inputProps={{
              name: 'mobileNo',
              required: true,
            }}
            inputStyle={{
              width: '91.6%',
              padding: '10px',
              backgroundColor: '#333333',
              color: '#ffffff',
              border: '1px solid #333333',
              borderRadius: '0 4px 4px 0',
              marginLeft:"30px"
            }}
            buttonStyle={{
              backgroundColor: '#333333',
              border: '1px solid #333333',
              borderRadius: '4px 0 0 4px',
              display: 'flex',
              alignItems: 'center',
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email ID</label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="enter email"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="address">Address</label>
          <textarea
            name="address"
            id="address"
            value={formData.address}
            onChange={handleChange}
            placeholder="enter address"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="noOfShares">No of Share:</label>
          <input
            type="number"
            name="noOfShares"
            id="noOfShares"
            value={formData.noOfShares}
            onChange={handleChange}
            placeholder="enter no of share"
            required
          />
          <small>* per share value INR 67,200</small>
        </div>

        <div className="form-group">
          <label htmlFor="totalAmount">Total Amount:</label>
          <input
            type="text"
            name="totalAmount"
            id="totalAmount"
            value={formData.totalAmount}
            onChange={handleChange}
            placeholder="INR"
            disabled
            required
          />
        </div>

        <button type="submit" className="submit-btn">Submit</button>

        <div className="investor-info">
          <p>We are only accepting 150 investors</p>
          <small>*{150-investmentCount} left</small>
        </div>
      </form>
    </div>    
    </>
   
  );
};

export default PitchDeckFrom;

